@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('./app/assets/fonts/PlusJakartaSans-Bold.eot');
  src:
    url('./app/assets/fonts/PlusJakartaSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('./app/assets/fonts/PlusJakartaSans-Bold.woff2') format('woff2'),
    url('./app/assets/fonts/PlusJakartaSans-Bold.woff') format('woff'),
    url('./app/assets/fonts/PlusJakartaSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('./app/assets/fonts/PlusJakartaSans-ExtraBold.eot');
  src:
    url('./app/assets/fonts/PlusJakartaSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./app/assets/fonts/PlusJakartaSans-ExtraBold.woff2') format('woff2'),
    url('./app/assets/fonts/PlusJakartaSans-ExtraBold.woff') format('woff'),
    url('./app/assets/fonts/PlusJakartaSans-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('./app/assets/fonts/PlusJakartaSans-ExtraLight.eot');
  src:
    url('./app/assets/fonts/PlusJakartaSans-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('./app/assets/fonts/PlusJakartaSans-ExtraLight.woff2') format('woff2'),
    url('./app/assets/fonts/PlusJakartaSans-ExtraLight.woff') format('woff'),
    url('./app/assets/fonts/PlusJakartaSans-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('./app/assets/fonts/PlusJakartaSans-SemiBold.eot');
  src:
    url('./app/assets/fonts/PlusJakartaSans-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./app/assets/fonts/PlusJakartaSans-SemiBold.woff2') format('woff2'),
    url('./app/assets/fonts/PlusJakartaSans-SemiBold.woff') format('woff'),
    url('./app/assets/fonts/PlusJakartaSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('./app/assets/fonts/PlusJakartaSans-Light.eot');
  src:
    url('./app/assets/fonts/PlusJakartaSans-Light.eot?#iefix') format('embedded-opentype'),
    url('./app/assets/fonts/PlusJakartaSans-Light.woff2') format('woff2'),
    url('./app/assets/fonts/PlusJakartaSans-Light.woff') format('woff'),
    url('./app/assets/fonts/PlusJakartaSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('./app/assets/fonts/PlusJakartaSans-Medium.eot');
  src:
    url('./app/assets/fonts/PlusJakartaSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('./app/assets/fonts/PlusJakartaSans-Medium.woff2') format('woff2'),
    url('./app/assets/fonts/PlusJakartaSans-Medium.woff') format('woff'),
    url('./app/assets/fonts/PlusJakartaSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('./app/assets/fonts/PlusJakartaSans-Regular.eot');
  src:
    url('./app/assets/fonts/PlusJakartaSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('./app/assets/fonts/PlusJakartaSans-Regular.woff2') format('woff2'),
    url('./app/assets/fonts/PlusJakartaSans-Regular.woff') format('woff'),
    url('./app/assets/fonts/PlusJakartaSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Courier Prime';
  src: url('./app/assets/fonts/CourierPrime-Bold.eot');
  src:
    url('./app/assets/fonts/CourierPrime-Bold.eot?#iefix') format('embedded-opentype'),
    url('./app/assets/fonts/CourierPrime-Bold.woff2') format('woff2'),
    url('./app/assets/fonts/CourierPrime-Bold.woff') format('woff'),
    url('./app/assets/fonts/CourierPrime-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Courier Prime';
  src: url('./app/assets/fonts/CourierPrime-Regular.eot');
  src:
    url('./app/assets/fonts/CourierPrime-Regular.eot?#iefix') format('embedded-opentype'),
    url('./app/assets/fonts/CourierPrime-Regular.woff2') format('woff2'),
    url('./app/assets/fonts/CourierPrime-Regular.woff') format('woff'),
    url('./app/assets/fonts/CourierPrime-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  box-sizing: border-box;
  padding: env(safe-area-inset);

  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight: normal;

  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #fff;

  height: 100%;

  font-size: 16px;
  line-height: 24px;
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 14px;
    line-height: 18px;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  display: block;
  margin: 0;
  padding: 0;
  background-color: #040406;
  height: var(--dvh-screen);
  overflow-x: hidden;
  transition: background-color 0.15s linear;
  position: relative;
  scroll-behavior: smooth;
  scrollbar-gutter: stable;
  /* overflow-y: scroll !important; */

  --w3m-z-index: 1000;
}

#root {
  min-height: var(--dvh-screen);
  height: max-content;
  position: relative;
  display: flex;
  flex-direction: column;
}
#modal-portal,
#modal-portal-tsx {
  z-index: 2;
}

/** @see https://stackoverflow.com/questions/5069752/ios-4-2-webfont-ttf-s-bold-font-weight-rendering-bug/11879044#11879044 */
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: normal;
}

strong {
  font-weight: 600;
}

p {
  margin: 0;
  padding: 0;

  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

sup,
sub {
  font-size: 0.47em;
  padding: 0 2px;
}

sup {
  vertical-align: super;
}

sub {
  vertical-align: sub;
}

b,
strong {
  font-weight: 600;
}

i {
  font-style: italic;
}

a {
  color: inherit;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.35);
}
::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 0.15s ease-in-out;
  background-color: rgba(0, 0, 0, 0.4) !important;
  z-index: 990;
  width: 100%;
  padding: 12px;
  display: flex;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

:root {
  --dvh-screen: 100dvh;
}

@supports not (min-height: 100dvh) {
  :root {
    --dvh-screen: 100vh;
  }
}
